#invoice {
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  box-sizing: border-box;
  margin: 5px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  position: relative;
}

#info {
  padding-bottom: 30px;
  border-bottom: solid 1px #c1c1c1;
}

#confirmPayment {
  text-align: center;
}

#confirmPaymentMessage {
  font-size: 1.1em;
}

#status {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

#confirmInvoiceOptions {
  display: flex;
  justify-content: space-between;
}

#payInvoiceBtn {
  background: #4a91fb;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 1em;
  float: left;
}

#cancelInvoiceBtn {
  background: none;
  color: #5b4afb;
  font-size: 0.7em;
  width: 100px;
}

#invoicePayComplete {
  background: none;
  color: #5b4afb;
  font-size: 0.9em;
  width: 100px;
}

@media screen and (min-width: 900px) {
  #invoice {
    width: 600px;
  }

  #invoice #info {
    width: 580px;
  }

  #invoice #payInvoiceBtn {
    width: 300px;
  }

  #confirmInvoiceOptions {
    justify-content: center;
  }
}