* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Fredoka";
  src: url('./fonts/Fredoka.ttf');
}

#root {
  padding-top: 5px;
}

.desktop {
  display: block;
}

body,
#app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #b44afb;
  background-size: auto;
  font-family: 'Roboto', 'Fredoka', sans-serif;
  max-width: 620px;
}

h1,
h2,
h3,
h4,
h5,
b {
  font-weight: 500;
}

button,
#invoice_btn,
.account_button,
.button {
  font-family: 'Roboto', 'Fredoka', sans-serif;
  border: none;
  padding: 3px;
  background: #4a91fb;
  color: #ffffff;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 3px;
  border-radius: 3px;
  width: 70%;
  cursor: pointer;
}

#invoice_btn,
.account_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-family: 'Roboto', 'Fredoka', sans-serif;
}

h3 {
  color: #000;
}

.box {
  border-radius: 2px;
  margin: 1px;
  padding: 10px;
  background: #fff;
  transition-property: height;
  transition-duration: 0.5s;
  overflow-x: hidden;
}

.active {
  width: calc(100vw - 24px);
  height: calc(100vh - 142px);
  margin-bottom: 0;
  border-radius: 2px 2px 25px 25px;
}

.inactive {
  width: calc(33.33vw - 22.6666px);
  height: 60px;
  padding: 0 10px;
  margin-top: 3px;
  overflow: hidden;
}

.navBar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin-top: -5px;
  padding-top: 5px;
  text-align: center;
  background: #b44afb;
  z-index: 6;
}

.navBarBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 55px;
  background: #b44afb;
  z-index: 5;
  transition: 0.2s;
}

.navBarOpen {
  transition: 0.2s;
  background: white;
}

.navBar img {
  height: 55px;
  margin-top: -3px;
  filter: unset;
}

.navBarOpen img {
  -webkit-filter: invert(50%) sepia(72%) saturate(5923%) hue-rotate(255deg) brightness(100%) contrast(98%);
  filter: invert(50%) sepia(72%) saturate(5923%) hue-rotate(255deg) brightness(100%) contrast(98%);
  ;
}

.toggleNav {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 30px;
  color: white;
  padding: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0;
  rotate: 90deg;
  transition: 0.2s;
  cursor: pointer;
}

#closeNav {
  rotate: -90deg;
  color: #b44afb;
}

.navActive {
  z-index: 3;
  opacity: 1;
  rotate: 0deg;
}

#menu {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
}

.popup {
  position: fixed;
  width: 0px;
  height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  top: 55px;
  left: 0;
  background-color: #fff;
  color: #000;
  text-align: left;
  padding: 10px 0;
  transition: 0.2s;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 5;
}

.navOpen {
  width: 250px;
  padding: 10px;
}

#navContent {
  position: relative;
  padding: 10px;
  margin: 5px;
}

input {
  border-radius: 5px;
  padding: 5px 2px;
}

#qr-canvas {
  width: 270px;
}

#qrcode {
  display: block;
  position: relative;
  margin: auto;
  color-scheme: only light;
}

#withdrawAmount {
  width: 60%;
}

#max {
  background: #4a91fb;
  color: #ffffff;
  font-weight: bold;
  border: solid 1px #4a91fb;
  margin-top: 10px;
  margin-left: 5px;
  border-radius: 5px;
  width: 25%;
}

.historyStyleGreen {
  color: green;
  overflow-x: auto;
  background: #f5f5f5;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 0;
  min-height: auto;
  padding: 20px;
  text-decoration: none;
}

.historyStyleRed {
  color: red;
  overflow-x: auto;
  background: #f5f5f5;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 0;
  min-height: auto;
  padding: 20px;
  text-decoration: none;
}

.historyStyleGreen p,
.historyStyleRed p {
  margin-top: 10px;
}

.renderAmount {
  font-weight: 600;
}

.renderDate {
  color: #000;
  font-weight: normal;
}

.renderMemo {
  color: #000;
  font-weight: normal;
  word-break: break-word;
}

#copyright {
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 13px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navOption:hover {
  background-color: #e9e9e9;
}

.navOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* font-style: italic; */
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  margin: 0;
  border-radius: 100px;
  cursor: pointer;
}

.navOption i {
  width: 35px;
  font-size: 20px;
  color: black;
  margin: 0px;
  margin-right: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: justify;
  justify-content: center;
  transition: all 0.35s ease-in;
  z-index: 7;
  overflow-y: auto;
  background: #ffffff;
  overflow-x: hidden;
}

.payModal {
  width: 90vw;
  height: 80vh;
  max-height: 550px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: left;
  justify-content: center;
  transition: all 0.35s ease-in;
  z-index: 6;
  border-radius: 15px;
  background: #ffffff;
  overflow: auto;
  overflow-x: hidden;
}

.modalContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 7;
}

.modal-dialog {
  position: relative;
  width: 95%;
  background: var(--white);
}

.modal-dialog>* {
  padding: 20px;
}

.modal-header {
  background: var(--lightgray);
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px black;
  width: calc(90%);
  padding: 20px;
}

.modal-content {
  padding: 0px 20px;
  box-sizing: border-box;
}

.close-modal {
  float: right;
  font-weight: 600;
}

li {
  margin-bottom: 10px;
}

.section {
  margin-bottom: 50px;
}

.border {
  border-top: solid 1px #f5f5f5;
  border-bottom: solid 1px #f5f5f5;
  padding: 10px 0;
  margin: 10px 0;
}

#qrImg {
  width: 55px;
  display: block;
  position: relative;
  padding: 8px;
}

h4 {
  color: #5b4afb;
  margin-bottom: 5px;
}

#btn-scan-qr {
  width: calc(100% + 6px);
  padding-left: -3px;
  margin-left: -3px;
  margin-bottom: -3px;
  padding-bottom: 9px;
  padding-top: 6px;
  border: none;
  border-radius: 0px 0px 3px 3px;
  overflow: hidden;
}

.buyPiButton {
  margin-bottom: 5px;
}

.createInvoice,
.createPayment,
.accountBalance {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 3px;
  text-align: left;
  margin-top: 40px;
}

.field {
  color: #030303;
  background: #ffffff;
  padding: 3px;
  font-size: 12px;
  border: none;
  width: 95%;
  margin-bottom: 3px;
}

.memo {
  height: 2.5rem;
}

.subheading {
  color: #030303;
  font-size: 14px;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

#username {
  position: absolute;
  bottom: 15px;
  left: 16px;
  color: #ffffff;
}

#balance,
#user {
  border: solid 1px #c1c1c1;
  border-radius: 3px;
  color: #030303;
  width: 100%;
  height: 25px;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #030303;
}

#wallet,
#crypto {
  border: none;
  padding: 3px;
  background: #4a91fb;
  color: #ffffff;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 3px;
  border-radius: 3px;
  width: calc(50% - 4px);
  cursor: pointer;
}

#crypto {
  margin-left: 3px;
}

.fa-minus,
.fa-plus {
  color: #5b4afb;
}

#tint,
#darkTint {
  background: black;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;
}

#tint {
  z-index: 4;
}

#darkTint {
  opacity: 0.5;
  z-index: 6;
}

#payment_sentence {
  font-size: 9px;
  margin-top: 10px;
}

#camSpan {
  margin-left: 7px;
  padding: 0px;
  color: #030303;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #b44afb;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: auto;
}

.btnLoader {
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  border-top: 2px solid #030303;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.paymentSuccessful {
  text-align: center;
  margin: auto;
}

.completeImg {
  margin: auto;
  width: 120px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#confirmPaymentQR {
  text-align: center;
}

#confirmPaymentQRBtn,
#cancelPaymentQRBtn,
#confirmPaymentUsernameBtn,
#cancelPaymentUsernameBtn {
  width: 45%;
  margin-top: 20px;
}

#invoicesModalHeader {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background: #ffffff;
}

.renderedInvoiceDiv {
  padding: 10px;
  padding-left: 5px;
  padding-right: 5px;
  /*   margin-bottom: 10px; */
  border-bottom: solid 1px #666666;
}

.renderedInvoiceInfoDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.renderedInvoiceTitle {
  margin-bottom: 0px;
  font-size: 0.8rem;
  color: black;
}

.renderedInvoiceMemo {
  padding: 5px;
  font-size: 0.75rem;
}

.renderedInvoiceDate {
  font-size: 0.7rem;
}

.renderedInvoiceStatus {
  font-size: 0.7rem;
  color: red;
}

.renderedInvoiceButton {
  position: absolute;
  right: 0px;
  bottom: 5px;
  width: 20px;
}

.statusTrue {
  color: green;
}

.fa-trash-o,
.fa-trash {
  color: red;
}

.fa-archive {
  color: green;
}

#username_input,
#password_input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 12px;
  border: solid 1px #c1c1c1;
}

#loginBtn {
  padding: 7.5px;
  font-size: 16px;
  width: 200px;
}

.consent {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #030303;
  text-align: left;
  color: white;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
}

.consent a {
  color: #5b4afb;
}

#closeCookies {
  border: none;
  color: white;
  padding: 20px;
  margin: 3px 20px 0px 15px;
  border-radius: 100px;
  background: #333;
  cursor: pointer;
  width: 10px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  float: right;
}

#hackathonNotice,
#addPassword {
  position: fixed;
  z-index: 100;
  height: 400px;
  width: 320px;
  top: calc(50vh - 200px);
  left: calc(50% - 160px);
  padding: 20px;
  box-sizing: border-box;
  word-break: break-word;
  background: white;
  border-radius: 5px;
  line-height: 1.5;
}

#hackathonNotice a {
  text-decoration: underline;
  cursor: pointer;
}

#closeHackathonNotice {
  margin-top: 20px;
  width: 100%;
}

#noticeTint {
  background: black;
  opacity: 0.5;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
}

#addPassword h2 {
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 30px;
}

#addPassword h3 {
  /* font-weight: normal; */
  color: #5b4afb;
  font-size: 18px;
  margin-bottom: 10px;
}

#addPassword i {
  font-size: 13px;
  color: #666666;
  margin-bottom: 15px;
  display: block;
}

#addPassword input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0px 0px 3px 1px #c1c1c1;
}

#addPassword span {
  display: flex;
  justify-content: space-between;
}

#addPasswordButton,
#skipPasswordButton {
  width: 130px;
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
  cursor: pointer;
}

#skipPasswordButton {
  background: none;
  color: #5b4afb;
}

#translate {
  position: fixed;
  top: calc(50vh - 220px);
  right: calc(50% - 115px);
  height: 440px;
  background: white;
  color: #c1c1c1;
  border: solid 1px #e9e9e9;
  border-radius: 5px;
  padding: 15px;
  font-size: 18px;
  overflow-y: auto;
  z-index: 10;
}

#translate a {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

#translate a:hover {
  background-color: #e9e9e9;
  border-radius: 100px;
}

#translate img {
  width: 30px;
  height: 22.5px;
  box-shadow: 0px 0px 5px 0px #c1c1c1;
  margin-right: 10px;
}

#translate::-webkit-scrollbar {
  width: 5px;
  background-color: #e9e9e9;
}

#translate::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

#welcomeNewUser {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  padding: 30px;
  box-sizing: border-box;
  background: #030303;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

/* Expanded stylesheet */
.subheading {
  font-size: 16px;
  margin-left: 2%;
}

form {
  font-size: 15px;
}

.field {
  margin-top: 10px;
  height: 20px;
  padding: 10px;
  width: 90%;
  margin-left: 2%;
}

.memo {
  height: 50px;
}

#invoice_btn,
.button {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-left: 2%;
}

#payment_sentence {
  font-size: 13px;
  margin-top: 20px;
  margin-left: 2%;
}

#qrImg {
  width: 100px;
  padding-left: 25px;
  padding-right: 25px;
}

#btn-scan-qr {
  padding: 12px;
}

#camSpan {
  padding-left: 60px;
  font-size: 20px;
}

#max {
  padding-top: 10px;
  padding-bottom: 10px;
}

#wallet,
#crypto {
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(48% - 3px);
}

#wallet {
  margin-left: 2%;
}

.container {
  margin-top: 10px;
}

.historyStyleGreen,
.historyStyleRed {
  font-size: 14px;
}

#withdrawBtn {
  background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
  color: white;
  padding: 7px;
  border-radius: 5px;
  margin-top: 15px;
  width: 120px;
  display: block;
}

.accountQR {
  margin-bottom: 10px;
}

#createQR {
  width: unset;
  padding: 5px 15px;
  margin-left: 20px;
}

#userQRModal {
  position: fixed;
  z-index: 10;
  height: 200px;
  width: 200px;
  top: calc(50vh - 100px);
  left: calc(50vw - 100px);
  padding: 15px;
  box-sizing: border-box;
  background: white;
}

@media screen and (min-width: 620px) and (max-width: 899px) {
  body {
    margin: auto;
  }

  .box {
    width: 250px;
  }

  .active {
    width: 90%;
    height: calc(100vh - 162px);
  }

  .inactive {
    max-width: 171.66666px;
    height: 80px;
  }

  .inactive img {
    padding: 10px;
  }

  .field {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 15px;
  }

  .subheading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 25px;
  }

  button,
  #invoice_btn,
  .button {
    font-size: 15px;
  }

  #qrImg {
    width: 70px;
    padding: 12px;
  }

  #payment_sentence {
    font-size: 15px;
  }

  .navBar {
    background: none;
  }
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none;
  }

  html {
    background: unset;
  }

  body {
    margin: auto;
    max-width: unset;
    background: unset;
  }

  #app {
    background: unset;
    max-width: 700px;
    margin: auto;
    min-height: calc(100vh - 350px);
  }

  .inactive {
    display: none;
  }

  .active {
    width: 100%;
    height: unset;
  }

  .navBar {
    position: fixed;
    max-width: 1200px;
    padding: 15px;
    margin: 0;
    text-align: right;
  }

  .navBar a {
    padding: 15px 20px;
    display: inline-block;
    color: white;
    font-size: 17px;
    cursor: pointer;
  }

  .navBar a:hover {
    text-shadow: 0 0 10px rgb(0 0 0 / 80%);
  }

  .navBarBg {
    height: 80px;
  }

  @media screen and (min-width: 1200px) {
    .navBar {
      left: calc(50% - 600px);
    }

    .dropdown {
      right: calc(50% - 600px);
    }
  }

  #footer {
    background-color: #f5f5f5;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    margin-left: -20px;
    margin-bottom: -20px;
    text-align: center;
    font-size: 14px;
    color: #777;
  
  }
  
  #footer a {
    color: #555;
  }
  
  #footerLinks {
    padding: 30px;
    text-align: left;
  }
  
  #footerBottom {
    padding: 20px;
    border-top: solid 1px black;
  }
  
  #socials {
    margin-top: 30px;
  }
  
  #socials a {
    padding: 20px;
  }

  #footer {
    max-width: 1200px;
  }
}