#pay {
  width: 100vw;
  height: calc(100vh - 20px);
  box-sizing: border-box;
  margin: 5px;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@font-face {
  font-family: "Roboto";
  src: url('./fonts/Fredoka.ttf');
}

#buttons {
  width: 100%;
}

#payBtn {
  font-family: "Fredoka", sans-serif;
  font-weight: 500;
  width: 100%;
  text-align: center;
  background-color: #b44afb;
  border: none;
  border-radius: 7px;
  margin-bottom: 15px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#btnLogo {
  height: 40px;
}

#btnMessage {
  font-size: 16px;
  color: white;
  letter-spacing: 0.1em;
  margin-left: -10px;
  margin-top: 10px;
  z-index: 1;
}

#backBtn {
  font-family: "Fredoka", sans-serif;
  width: 100%;
  letter-spacing: 0.1em;
  text-align: center;
  background-color: #666666c2;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 7px;
  padding: 5px 10px;
  cursor: pointer;
}

#backBtn:hover {
  background: #c1c1c1;
  color: black;
}

#returnBtn {
  border: solid 1px #666666;
  color: #666666;
  border-radius: 5px;
  padding: 2.5px 10px;
  cursor: pointer;
}

#successTxid {
  word-break: break-word;
  margin-top: 50px;
  text-align: left;
}

#copyTxid {
  display: inline-block;
  width: calc(100vw - 60px);
  margin-top: 5px;
  text-align: right;
}

#payeeSplitter {
  font-size: 12px;
  color: #666666;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#payeeSplitter span {
  width: calc(100% / 3);
  display: inline-block;
  text-align: center;
  height: 1px;
  background: #666666;
}

#payeeUsername {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666ce;
  background: #ffffff;
  font-size: 12px;
  border: none;
  margin-bottom: 3px;
  margin-top: 2px;
  height: 20px;
  padding: 10px;
  width: 90%;
  margin-left: 2%;
  border-radius: 5px;
}

.payee {
  color: #666666;
  background: #f1f1f1;
  border: none;
  margin: 15px 0;
  padding: 10px;
  width: calc(100% - 20px);
  border-radius: 3px;
}

.payee i {
  font-size: 12px;
}

#sandbox {
  background: rgb(224, 86, 86);
  color: white;
  font-size: 13px;
  padding: 2px 6px;
  border-radius: 3px;
}

@media screen and (min-width: 900px) {
  #pay {
    width: 600px;
  }

  #pay #info {
    width: 580px;
  }
}