#app.questions {
  width: 100vw;
  height: calc(100vh - 60px);
  background: white;
  overflow-y: auto;
  display: block;
  margin-top: 5px;
  padding: 50px 0px;
  box-sizing: border-box;
}

.questions h2 {
  margin-left: 20px;
}

.question {
  padding: 30px;
}

.question_odd {
  background: #f5f5f5;
}

.questions p {
  margin-top: 10px;
  text-align: justify;
}

.question_indent {
  margin-left: 15px;
  text-align: left;
}

@media screen and (min-width: 900px) {
  #app.questions {
    height: unset;
  }
}